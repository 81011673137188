* {
  scroll-behavior: smooth;
}

html {
  overflow: scroll;
}

body {
  width: 100%;
}
