/* You can add global styles to this file, and also import libraries style files */

// Overrides
@import "scss/bootstrapOverrides";
@import "scss/common";

// Libraries
@import "scss/dropShadow.scss";
@import "scss/scrollbar.scss";
@import "~bootstrap/scss/bootstrap";
@import "~react-table/react-table.css";
@import "~react-toastify/dist/ReactToastify.min.css";

// Font
@import "scss/font";

// Theme
@import "scss/themes/defaultLight";

// Mixins
@import "scss/mixins/resolutionMixins";

